import axios from "axios";
import { useState, useEffect } from "react";
import AuthService from "../services/AuthService";
import { Loading } from "../components/Loading";
import { HeaderTable } from "../components/HeaderTable";
import { Button, Link, Typography } from "@mui/material";
import DataService from "../services/DataService";
import { useNavigate } from "react-router-dom";
import MemberService from "../services/MemberService";
import { ProjectsIframe } from "./iframes/ProjectsIframe";
import { TaskDto } from '../types';
import TaskService from '../services/TaskService';
import styles from '../styles/Table.module.css';


interface LoanProps {
    isMobile: boolean
    config: Config
}

declare global {
    type Loan = {
        id: string,
        name: string,
        value?: number,
        signedDate: string,
        project: {name:string},
        payedDate: string,
        state: string,
        desiredValue?: string,
        lastModifiedDate: Date
    }
}

export const Loan: React.FC<LoanProps> = (props) => {
    const navigate = useNavigate();
    const [finishedLoading, setFinishedLoading] = useState<{loan: boolean}>({loan: false});
    const [tasks, setTasks] = useState<TaskDto[]>([]);

    const [loanData, setLoanData] = useState<Loan[]>([{
        id: "Nicht vorhanden",
        name: "Nicht vorhanden",
        value: 0.00,
        signedDate: "Nicht vorhanden",
        project: {name:"Nicht vorhanden"},
        payedDate: "Nicht vorhanden",
        state: "Unbekannt",
        lastModifiedDate: new Date()
    }]);

    useEffect(() => {
        const loadTasks = async () => {
            const taskList = await TaskService.getTaskList();
            setTasks(taskList);
        }
        
        if (!AuthService.isLoggedIn()) {
            AuthService.doLogin();
        } else {
            loadTasks();
            DataService.performRequest(`/rest/services/beg_PortalLoanService/getLoans${MemberService.getUrlAddition()}`,finishedLoading,setFinishedLoading,setLoanData,"loan", navigate)

        }
    }, []);
    

    if (!finishedLoading.loan) {
        return <Loading />
    }

    const extractTaskForLoan = (loanId: string): TaskDto | null  => {
        return tasks.find(task => task.variables['loanId'] === loanId)??null;
    }

    const to2dArray = (objectArray: any) => {
        let returnElement: any[] = [];
        objectArray.sort((a:Loan,b:Loan) => new Date(a.lastModifiedDate).getTime() - new Date(b.lastModifiedDate).getTime());
        objectArray.forEach((objectEl:Loan) => {
            returnElement.push([
                objectEl.name,
                getValueCell(objectEl),
                objectEl.project.name,
                getLoanState(objectEl.state),
                objectEl.signedDate ? new Date(objectEl.signedDate).toLocaleDateString() : "",
                objectEl.payedDate ? new Date(objectEl.payedDate).toLocaleDateString() : "",
                extractTaskForLoan(objectEl.id) ? renderTaskButton(extractTaskForLoan(objectEl.id)) : "Keine Aufgabe"
             ]);

        })
        return returnElement;
    }

    const getValueCell = (objectEl:Loan) => {
        if (objectEl.value) {
            return objectEl.value.toLocaleString(undefined,{minimumFractionDigits: 2}) + " €";
        } else {
            return <>BEANTRAGT {objectEl?.desiredValue} € {objectEl.state === "INTENT" && <>(<Link onClick={() => navigate(`/projectloans/edit/${objectEl.id}`)}>Ändern</Link>)</>}</>
        }
    }

    const getTaskText = (key: string) => {
        switch(key){
            case "sign_loan_contract": return "Darlehensvertrag unterschreiben";
            case "check_changes": return "Änderungen überprüfen"
            default: return key
        }
    }

    const renderTaskButton = (taskDto: TaskDto |null) => {
        if(taskDto == null) return "Keine Aufgabe"
        return (<Button variant="contained" size="small" onClick={() => {
                navigate(`/tasks/task/${taskDto.id}`);
            }
        }>{getTaskText(taskDto.taskDefinitionKey)}</Button>)
    }


    

    const getLoanState = (state: string) => {
        //ist im System schon rechtschreibfehler, hab den nur übernommen (canceled)
        if (state === "CANCELED") return "Storniert"
        else if (state === "CLOSED") return "Geschlossen"
        else if (state === "DRAFT") return "Entwurf"
        else if (state === "INTENT") return "Absichtserklärung"
        else if (state === "PAYED") return "Bezahlt"
        else if (state === "SENT") return "Vertrag versendet"
        else if (state === "SIGNED") return "Unterschrieben"
        else if (state === "CHANGES_REQUESTED") return "Änderungen angefragt"
        else if (state === "APPROVED") return "Freigegeben"
        else return "Unbekannt"
    }
    const headerRows = ["Bezeichnung","Wert","Projektname","Darlehenstatus","Unterschrieben am","Ein-/Auszahlung am","Aufgabe"];
    let colorChange = false;
    return (
        <div className="content">
            <Typography variant="h6">Projektdarlehen</Typography>
            
            {

                <div style={props.isMobile ? {overflow: "scroll"} : {}}>
                <table className={styles.titletable}><tbody>
                    <tr>
                        {headerRows.map((headerRow: any) => <th key={Math.random()} className={styles.titletableTitle}>{headerRow}</th>)}
                    </tr>
                    {to2dArray(loanData).map((row: any) => {
                        colorChange = !colorChange;
                        return <tr key={Math.random()}>
                            {row.map((cell: any) => 
                                <td key={Math.random()} className={styles.titletableCell} style={{ backgroundColor: colorChange ? "#F8F8F8" : "#eaf4e5" }}>{cell}</td>
                            )}
                        </tr>
                    })}
                </tbody></table></div>
}
            <div style={{marginTop: 15}}>
                <Typography variant="h6">Aktuelle Projekte</Typography>
                <ProjectsIframe isMobile={props.isMobile} config={props.config} inPortal={true} />
            </div>
            
        </div>
    )

}