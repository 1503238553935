import axios from "axios";
import { useState, useEffect } from "react";
import AuthService from "../services/AuthService";
import { Loading } from "../components/Loading";
import { HeaderTable } from "../components/HeaderTable";
import { Link, Typography } from "@mui/material";
import DataService from "../services/DataService";
import { useNavigate } from "react-router-dom";
import MemberService from "../services/MemberService";


interface AttachmentsProps {
    isMobile: boolean
    config: Config
}

declare global {
    type Attachment = {
        id: string,
        name: string,
        file: string,
        source: string,
        isPublic: boolean,
        createdDate: string
    }
}

export const Attachments: React.FC<AttachmentsProps> = (props) => {
    const navigate = useNavigate();
    const [finishedLoading, setFinishedLoading] = useState<{attachments: boolean, publicAttachments: boolean}>({attachments: false, publicAttachments: false});

    const [attachmentsData, setAttachmentsData] = useState<Attachment[]>([{
        id: "unbekannt",
        name: "Nicht vorhanden",
        file: "#",
        source: "Nicht vorhanden",
        isPublic: false,
        createdDate: "Nicht vorhanden"
    }]);
    const [publicAttachmentsData, setPublicAttachmentsData] = useState<Attachment[]>([]);

    useEffect(() => {
        if (!AuthService.isLoggedIn()) {
            AuthService.doLogin();
        } else {
            DataService.performRequest(`/rest/services/beg_PortalAttachmentService/getAttachments${MemberService.getUrlAddition()}`,finishedLoading,setFinishedLoading,setAttachmentsData,"attachments", navigate)
            DataService.performRequest(`/rest/services/beg_PortalAttachmentService/getPublicAttachments`,finishedLoading,setFinishedLoading,setPublicAttachmentsData,"publicAttachments", navigate)
        }
    }, []);

    const booleanToJaNein = (bool: boolean) => {if(bool) {return "Ja"} else return "Nein"}
    

    if (!finishedLoading.attachments) {
        return <Loading />
    }

    const to2dArray = (objectArray: any) => {
        let returnElement: any[] = [];
        objectArray.map((objectEl:Attachment) => {
            returnElement.push([
                objectEl.createdDate ? new Date(objectEl.createdDate).toLocaleDateString() : "",,
                objectEl.name,
                objectEl.source,
                booleanToJaNein(objectEl.isPublic),
                <Link onClick={() => {DataService.downloadAttachment(objectEl.file, objectEl.name);}}>Herunterladen</Link>
             ]);
        })
        return returnElement;
    }
    
    return (
        <div className="content">
            <Typography variant="h6">Dokumente</Typography>
            <p>Es wurden {attachmentsData.concat(publicAttachmentsData).length} Dokumente gefunden</p>
            <HeaderTable headerRows={["Datum","Bezeichnung","Kategorie","Öffentlich","Aktionen"]}
            rows={
                to2dArray(attachmentsData.concat(publicAttachmentsData))
            } isMobile={props.isMobile}
            />
            <Typography variant="caption">Die Dokumentkategorien werden noch überarbeitet</Typography>
        </div>
    )


}