import { Typography } from '@mui/material';
import MemberService from '../services/MemberService';
import styles from '../styles/SwitchMember.module.css';
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import DataService from '../services/DataService';
import { Loading } from '../components/Loading';

interface SwitchMemberProps {
    isMobile: boolean
    config: Config
}

export const SwitchMember: React.FC<SwitchMemberProps> = (props) => {
    const navigate = useNavigate();
    const [finishedLoading, setFinishedLoading] = useState<{member: boolean}>({member: false});

    useEffect(() => {
        DataService.performRequest(`/member`,finishedLoading,setFinishedLoading,MemberService.setMemberData,"member", navigate)
    }, [])

    if (!finishedLoading.member) {
        return <Loading />
    }

    return (
        <div className="content">
            <Typography variant="h6">Mitgliedschaft auswählen</Typography>
            <Typography variant="body2">Ihr Benutzeraccount ist möglicherweise mehreren Mitgliedschaften zugeordnet. Hier können Sie zwischen diesen Mitgliedschaften wechseln.</Typography>
            <Typography variant="caption">Achtung! Bevor sie Verträge abschließen, sollten Sie sich vergewissern, welche Mitgliedschaft Sie aktuell ausgewählt haben!</Typography>
            {MemberService.getMemberData().map((obj:Member) => (
                <div key={obj.id} className={styles.box} style={{borderLeft: `solid 4px ${obj.id === MemberService.getCurrentMember().id ? 'var(--c1)' : 'white'}`}} onClick={() => {navigate('/');MemberService.setCurrentMember(obj)}}><p className={styles.number}>{obj.memberNumber}</p> {obj.name}</div>
            ))}
        </div>
    )
}
