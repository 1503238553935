import { Button, Stack, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import AuthService from '../../../services/AuthService';
import DataService from '../../../services/DataService';
import { TaskDto } from '../../../types';
import TaskService from '../../../services/TaskService';

interface TaskProps {
  isMobile: boolean;
  task: TaskDto;
}

declare global {}

export const CheckChangesTask: React.FC<TaskProps> = (props) => {
  const navigate = useNavigate();

  useEffect(() => {

    if (!AuthService.isLoggedIn()) {
      AuthService.doLogin();
    }
  }, []);

  return (
    <div className='content'>
      <Typography variant='h6'>Änderungen wurden angefragt!</Typography>
      <Typography>Der Genossenschaftskoordinator hat Änderungen für das Darlehen angefragt!</Typography>
      <Typography>Begründung: "{props.task.variables.note}"</Typography>
      <Typography>Vorschlag für einen neuen Darlehenswert: {props.task.variables.newValue}€</Typography>
      <Stack spacing={2} direction="row">
      <Button  variant="contained" color="primary" onClick={async () => {
        await TaskService.complete(props.task.id, {decisionOnChangeRequest: "approved"});
        navigate("/projectloans");
        }}>
            Annehmen
      </Button>
      <Button  variant="contained" color="error" onClick={async () => {
        await TaskService.complete(props.task.id, {decisionOnChangeRequest: "declined"});
        navigate("/projectloans");
        }}>
            Ablehnen
      </Button>
    </Stack>
      
    </div>
  );
};
