import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import AuthService from './services/AuthService';
import { Health } from './pages/Health';

/*const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);*/

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(<p>Lade...</p>)

if (window.location.pathname == "/health"){
  root.render(<Health/>)
} else {
  AuthService.initKeycloak().then(() => {
    root.render(<App/>)
  });
}

