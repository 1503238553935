import { Button, Link, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { HeaderTable } from "../components/HeaderTable";
import { Loading } from "../components/Loading";
import { TitleTable } from "../components/TitleTable";
import AuthService from "../services/AuthService";
import DataService from "../services/DataService";
import MemberService from "../services/MemberService";
import EmptyTypesService from "../services/EmptyTypesService";


interface DashboardProps {
    isMobile: boolean
    config: Config
}
declare global {
    type Project = {
        id: string,
        name: string,
        website: string,
        state: string
    }
}

export const Dashboard: React.FC<DashboardProps> = (props) => {
    const navigate = useNavigate();
    const [finishedLoading, setFinishedLoading] = useState<{ minimalMember: boolean, projects: boolean }>({ minimalMember: false, projects: false });
    const [minimalMemberData, setMinimalMemberData] = useState<Member>(EmptyTypesService.emptyMember());
    const [projectData, setProjectData] = useState<Project[]>([EmptyTypesService.emptyProject()]);

    useEffect(() => {
        if (!AuthService.isLoggedIn()) {
            AuthService.doLogin();
        } else {
            DataService.performRequest(`/member/info?minimal=true${MemberService.getUrlAddition(true)}`, finishedLoading, setFinishedLoading, setMinimalMemberData, "minimalMember", navigate)
            DataService.performRequest(`/rest/services/beg_PortalProjectService/getProjects`, finishedLoading, setFinishedLoading, setProjectData, "projects", navigate)
        }
    }, []);

    const to2dProjectsArray = (objectArray: any) => {
        let returnElement: any[] = [];
        objectArray.map((objectEl: Project) => {
            returnElement.push([
                ((objectEl.website != null) ? <Link onClick={() => window.open(`${objectEl.website}`, '_blank')}>{objectEl.name}</Link> : <p>{objectEl.name}</p>),
                getProjectState(objectEl.state)
            ]);
        })
        return returnElement;
    }

    const getProjectState = (state: string) => {
        if (state === "CANCELLED") return "Eingestellt"
        else if (state === "CONSTRUCTION") return "Im Bau"
        else if (state === "FINANCING") return "Finanzierungsphase"
        else if (state === "OPERATIONAL") return "In Betrieb"
        else if (state === "PLANNING") return "In Planung"
        else return "Unbekannt"
    }

    if (!finishedLoading.minimalMember && !finishedLoading.projects) {
        return <Loading />
    }
    return (
        <div className="content">
            <Typography variant="h6">Dashboard</Typography>
            <div className="tableDiv" style={{ width: props.isMobile ? "100%" : "50%", display: "inline-block" }}>
                <Typography variant="subtitle1">Persönliche Daten</Typography>
                <TitleTable title="Kontaktdaten" rows={minimalMemberData.type === "beg_Contacts" ? [
                    { name: "Mitgliedsnummer", value: minimalMemberData.memberNumber },
                    { name: "Vorname", value: minimalMemberData.firstname },
                    { name: "Nachname", value: minimalMemberData.lastname },
                    { name: "Straße", value: minimalMemberData.address_street },
                    { name: "Postleitzahl", value: minimalMemberData.address_postalcode },
                    { name: "Ort", value: minimalMemberData.address_city },
                    { name: "E-Mail-Adresse", value: minimalMemberData.primaryEmail },
                ] : [
                    { name: "Mitgliedsnummer", value: minimalMemberData.memberNumber },
                    { name: "Name", value: minimalMemberData.name },
                    { name: "Straße", value: minimalMemberData.address_street },
                    { name: "Postleitzahl", value: minimalMemberData.address_postalcode },
                    { name: "Ort", value: minimalMemberData.address_city },
                    { name: "E-Mail-Adresse", value: minimalMemberData.primaryEmail },
                ]} />
                <Button fullWidth variant="outlined" onClick={() => navigate('/personaldata')}>Mehr</Button>
            </div>
            <div className="tableDiv" style={{ width: props.isMobile ? "100%" : "50%", display: "inline-block", verticalAlign: "top" }}>
                <Typography variant="subtitle1">Aktuelle Projekte</Typography>
                <HeaderTable headerRows={["Name", "Status"]}
                    rows={
                        to2dProjectsArray(projectData)
                    } isMobile={props.isMobile}
                />
            </div>
        </div>
    )

}
