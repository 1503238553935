import { LoadingButton } from "@mui/lab";
import { Typography, TextField, FormHelperText } from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import EmptyTypesService from "../../services/EmptyTypesService";
import AuthService from "../../services/AuthService";
import DataService from "../../services/DataService";
import { Loading } from "../../components/Loading";
import InputValue from "../../classes/InputValue";
import { validationService } from "../../services/ValidationService";

interface EditLoanApplicationDataProps {
    isMobile: boolean
    config: Config
}

export const EditLoanApplication: React.FC<EditLoanApplicationDataProps> = (props) => {
    const navigate = useNavigate();
    const { id } = useParams();
    const [saveBtnIsLoading, setSaveBtnIsLoading] = useState<boolean>(false)
    const [finishedLoading, setFinishedLoading] = useState<{ editLoan: boolean }>({ editLoan: false });
    const [isError] = useState<boolean>(false)
    const [loanData, setLoanData] = useState<Loan[]>([EmptyTypesService.emptyLoan()]);
    const [values] = useState<any>({
        loanSum: new InputValue(props.config.loanMinSum, false),
    });
    //a useState var needs to change in order to reload the screen, so you can do that with reload(!reloadVar);
    const [reloadVar, reload] = useState<boolean>(false)


    useEffect(() => {
        if (!AuthService.isLoggedIn()) {
            AuthService.doLogin();
        } else {
            DataService.performAsyncRequest(`/loan/${id}`, finishedLoading, () => { }, setLoanData, "editLoan", navigate)
        }

    }, []);

    //Save request
    const saveDesiredValue = async (setSaveBtnIsLoading: Function) => {
        DataService.performAsyncPutRequest(`/loan`, navigate,
            {
                id: loanData[0].id,
                desiredValue: values.loanSum.getValue(),
            }
        ).then(() => {
            setSaveBtnIsLoading(false);
            navigate('/projectloans')
        })
    }
    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const valueBefore = values["loanSum"].getValue();
        values["loanSum"].setValue(e.target.value)
        const newValue = parseInt(e.target.value);
        if (!validationService.validateFunction(values, "loanSum", "positiveFloat", false, false, props.config)) {
            values["loanSum"].setValue(valueBefore);
        }
        if(newValue % 1000 !== 0) {
            values["loanSum"].setError("Darlehensbetrag muss in 1000er Schritten angegeben werden!");
        }

        reload(!reloadVar);
    }

    const style = {
        marginTop: "10px",
    }

    if (!finishedLoading.editLoan) {
        return <Loading />
    }
    return (
        <div className="content">
            <div className="tableDiv" style={{ width: props.isMobile ? "100%" : "50%", display: "inline-block" }}>
                <Typography variant="h6">Gewünschte Darlehenssumme ändern</Typography>
                <div >
                    {loanData[0].state !== "INTENT" && <Typography variant="caption" color={"#d32f2f"}>Achtung! Das Darlehen kann nicht mehr bearbeitet werden, da es bereits angenommen wurde oder eine Änderung durch die BEG angefragt wurde!</Typography>}
                </div>
                <TextField type='number' fullWidth style={style} value={values.loanSum.getValue()} onChange={handleChange} error={values.loanSum.getError()} name='loanSum' label="Neuer Gewünschter Betrag des Darlehens" variant="outlined" size="small" required InputProps={{
                    inputProps: {
                        min: props.config.loanMinSum,
                        step: 1000
                    }
                }} />
                {values.loanSum.getError() ? (<FormHelperText error={true}>
                    Der Darlehensbetrag darf nur in 1000er Schritten gewählt werden!
                </FormHelperText>) : ''}

                <div style={{ textAlign: "right" }}>
                    {isError && <Typography variant="caption" color={"#d32f2f"}>Bitte überprüfen Sie ihre Eingabe und speichern erneut</Typography>}
                </div>
                <div style={{ textAlign: "right" }}>
                    <LoadingButton loading={saveBtnIsLoading} variant="contained" disableElevation sx={{ marginTop: "10px" }} onClick={async () => {
                        setSaveBtnIsLoading(true);
                        saveDesiredValue(setSaveBtnIsLoading);
                    }}>Speichern</LoadingButton>

                </div>

            </div>
        </div>
    )
}
