import axios from "axios";
import reCAPTCHA from "./CaptchaService";

export const validationService: {[index: string]: Function;} = {
    alphabet: (value: string) => {
        const regex = /^[^0-9,!"§$%&/()<>=?\\]+$/;
        return regex.test(value)
    },
    number: (value: number) => {
        return true;
    },
    street: (value: string) => {
        const regex = /^([\S\s]+?)\s+([\d-\s]*?)\s*([\w])?$/;
        return regex.test(value)
    },
    postalcode: (value: string) => {
        const regex = /^[0-9]{5}$/;
        return regex.test(value)
    },
    phone: (value: string) => {
        const regex = /^\+?[0-9 /-][0-9 /-]{7,20}$/;
        return regex.test(value)
    },
    email: (value: string) => {
        const regex = /^[\w-.]+@([\w-]+\.)+[\w-]{2,10}$/;
        return regex.test(value)
    },
    taxnumber: (value: string) => {
        //Deutsche Steueridentifikationsnummer
        const regex = /^[0-9]{11}$/;
        if (!regex.test(value)) return false;
        const arr = value.split("");
        let product: number = 10;
        for (let i = 0; i<10; i++) {
            let sum = (parseInt(arr[i]) + product) % 10;
            if (sum === 0) sum = 10;
            product = (sum * 2) % 11;
        }
        let prüfziffer = 11 - product;
        if (prüfziffer === 10) prüfziffer = 0;
        if (parseInt(arr[10]) === prüfziffer) return true;
        else return false;
    },
    birthdate: (value: string) => {
        const regex = /^([12]\d{3}-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01]))/;
        if (regex.test(value)) {
            let date = new Date(value)  // Past Date
            let diff = new Date().getTime() - date.getTime();
            if (diff > 0) {
                return true;
            } else return false;
        } else {
            return false;
        }

    },
    iban: (value: string) => {
        const regex = /^[A-Z]{2}(?:[ ]?[0-9]){18,20}$/;
        return regex.test(value)
    },
    bic: (value: string) => {
        const regex = /^([a-zA-Z]{4}[a-zA-Z]{2}[a-zA-Z0-9]{2}([a-zA-Z0-9]{3})?)$/;
        return regex.test(value)
    },
    nocheck: (value: any) => {
        return true;
    },
    positiveFloat: (value: string) => {
        if (typeof parseFloat(value) == 'number'){
            if (parseFloat(value) > 0.0) {
                return true;
            }
        }
        return false;
    },
    //!!! Only input number on the portal is for the amount of shares, thats why every number is treated as a shares amount
    validateFunction: (values:any,name: string, checkFor: string, isNextStep: boolean, isRequired: boolean, config: Config) => {
        const value = values[name].getValue();
        if (isNextStep && isRequired) {
            if (typeof parseInt(value) == 'number' && checkFor === "number") {
                if (value < 1 || value > config.maxAllowedHeldShares) {
                    values[name].setError(true);
                    return false;
                }
            } else {
                if (value.length < 1) {
                    values[name].setError(true);
                    return false;
                }
            }
            //only iban field (sepa) is required and should not only be checked onBlur but also onNextStep
            if (checkFor === "iban") {
                if (values[name].getError()) return false;
            }
        } else {
            if (typeof parseInt(value) == 'number' && checkFor === "number") {
                if (value.length < 1) {
                    values[name].setError(false);
                    return true;
                }
                if (value < 1 || value > config.maxAllowedHeldShares) {
                    values[name].setError(true);
                    return false;
                }
            } else {
                if (value.length < 1) {
                    values[name].setError(false);
                    return true;
                }
            }
        }
        if (!validationService[checkFor](value)) {
            values[name].setError(true);
            return false;
        }
        values[name].setError(false);
        return true;
    },
    validateIbanFromBackend: async (values: any,ibanString: string, bankBicString: string, bankNameString: string, config: Config , resolve: Function) => {
        validationService.validateFunction(values, ibanString, "iban", false, false, config);
        if (!values[ibanString].getError()) {
            const recaptcha = new reCAPTCHA((process.env.6LeVC68mAAAAAKkg1FwE7VTKP6rph1arAu1BZfi8!), "validateIban");
            //sends request to google
            let token: string = await recaptcha.getToken();

            axios.post(`${process.env.https://crm.dev.beg-verwaltung.de}/rest/services/beg_MemberApplicationService/validateIban`, {
                iban: values[ibanString].getValue(),
                grtoken: token
            }).then(function (response) {
                console.log(response);
                if (response.data.status === "OK") {
                    values[ibanString].setError(false);
                    if (response.data.body != null) {
                        const bank = response.data.body;
                        values[bankBicString].setValue(bank.bic);
                        values[bankNameString].setValue(bank.name);
                        values[bankBicString].setError(false);
                        values[bankNameString].setError(false);
                    }
                } else {
                    values[ibanString].setError(true);
                    values[bankBicString].setValue("");
                    values[bankNameString].setValue("");
                }
                resolve();
            }).catch(function (error) {console.log(error);})
        } else { resolve(); }
    }
}
